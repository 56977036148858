<template>
  <v-container fluid>
    <v-toolbar v-if="designFilePackage" dense flat>
      <span class="font-weight-bold">
        Design File Package Version
        {{ designFilePackage.version }}
      </span>
    </v-toolbar>
    <v-card flat>
      <v-row>
        <v-col cols="12" md="5" class="d-flex align-center">
          <v-container fluid>
            <v-img v-if="thumbnail" max-height="400" :src="thumbnail"></v-img>
            <div v-else class="text-center font-weight-bold">
              Fixture Design not yet rendered.
            </div>
          </v-container>
        </v-col>
        <v-col cols="12" md="7">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <v-row dense>
                  <v-col cols="12" md="4">
                    <span class="font-weight-bold">Fixture Model:</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="font-weight-bold warning--text">{{
                      fixtureDescription
                    }}</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <span class="font-weight-bold">Total DUTs:</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="font-weight-bold warning--text">{{
                      dutConfigs ? dutConfigs.length : 0
                    }}</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <span class="font-weight-bold">Total Test Points:</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="font-weight-bold warning--text">{{
                      testPoints
                    }}</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <span class="font-weight-bold">Total Guide Pins:</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="font-weight-bold warning--text">{{
                      guidePins
                    }}</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <span class="font-weight-bold">Total Pressure Pins:</span>
                  </v-col>
                  <v-col cols="12" md="8">
                    <span class="font-weight-bold warning--text">{{
                      pressurePins
                    }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row dense>
                  <v-col cols="12" class="justify-end align-start">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span class="ma-0" v-on="on">
                          <v-btn
                            v-if="isAdmin"
                            block
                            color="secondary"
                            :disabled="!fixtureDesignPackageUrl"
                            @click="
                              onDownloadFilePackage(fixtureDesignPackageUrl)
                            "
                            class="ma-0"
                            >Download Design File Package<v-icon right>
                              mdi-cloud-download
                            </v-icon></v-btn
                          ></span
                        >
                      </template>
                      <span>{{
                        fixtureDesignPackageUrl
                          ? fixtureDesignPackageUrl
                              .split("?")[0]
                              .split("/")
                              .pop()
                          : "No files to download"
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row v-if="!isModalRendered" dense>
                  <v-col cols="12" class="d-flex justify-end align-start">
                    <AddNotesModal
                      v-if="isAdmin"
                      :currentNote="designFilePackage.notes"
                      @saveNotes="
                        onPatchDFP({
                          id: designFilePackage.id,
                          fieldName: 'notes',
                          data: $event,
                        })
                      "
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!isModalRendered" dense>
                  <v-col cols="12" class="d-flex justify-end align-start">
                    <v-spacer />
                    <v-btn
                      v-if="isAdmin && isDFPView"
                      block
                      color="secondary"
                      :disabled="disableActiveButton"
                      @click="
                        onPatchDFP({
                          id: designFilePackage.id,
                          fieldName: 'active',
                          data: !designFilePackage.active,
                        })
                      "
                      >{{
                        designFilePackage.active ? "Set Inactive" : "Set Active"
                      }}</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row v-if="!isModalRendered" dense>
                  <v-col cols="12" class="d-flex justify-end align-start">
                    <v-spacer />
                    <v-btn
                      v-if="isAdmin && isDFPView"
                      block
                      color="warning"
                      @click="onDeleteFilePackage(designFilePackage.id)"
                      >Delete Design File Package</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row v-if="!isModalRendered" dense>
                  <v-col cols="12" class="d-flex justify-end align-start">
                    <v-btn
                      v-if="isAdmin"
                      block
                      :disabled="!bomUrl"
                      color="warning"
                      target="_blank"
                      :href="bomUrl"
                      data-test="bom-button"
                      >Go to Full BOM View
                      <v-icon small class="ml-1">
                        mdi-open-in-new
                      </v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <DutConfigBlock
                  :dutConfigs="dutConfigs"
                  :fixtureDev="fixtureDev"
                  :p100Count="p100Count"
                  :p75Count="p75Count"
                  :p50Count="p50Count"
                  :fixtureState="fixtureState"
                  :isAdmin="isAdmin"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
    <v-toolbar
      v-if="designFilePackage.notes"
      dense
      flat
      class="title mb-10"
      outlined
    >
      <span> Notes: {{ designFilePackage.notes }} </span>
    </v-toolbar>
    <v-card flat>
      <v-row>
        <v-col cols="12">
          <span class="px-2 title font-weight-bold">{{
            isAdmin
              ? "Individual Design FIles"
              : "Purchase Fixture to download design files"
          }}</span>
          <v-btn
            v-if="isAdmin && !isModalRendered"
            text
            color="secondary"
            @click="showDesignFileTable = !showDesignFileTable"
            >{{ showDesignFileTable ? "Hide Table" : "Show Table" }}</v-btn
          >
        </v-col>
      </v-row>
      <v-expand-transition>
        <div v-show="showDesignFileTable">
          <v-divider></v-divider>
          <v-row v-if="!isModalRendered">
            <v-col cols="12">
              <v-btn
                text
                color="secondary"
                @click="showAllFiles = !showAllFiles"
                class="mt-2"
                >{{
                  showAllFiles ? "Hide Missing Files" : "Show All Design Files"
                }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-list subheader flat>
                <v-subheader v-if="designFiles.length > 0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      class="font-weight-bold body-1 text-center"
                    >
                      Package File
                    </v-col>
                    <v-divider vertical />
                    <v-col
                      cols="12"
                      md="3"
                      class="font-weight-bold body-1 text-center"
                    >
                      {{ isAdmin ? "Generated File" : "File" }}
                    </v-col>
                    <v-divider vertical />
                    <v-col
                      v-if="isAdmin && !isModalRendered"
                      cols="12"
                      md="2"
                      offset-md="3"
                      class="font-weight-bold body-1 text-center"
                    >
                      Revised File
                    </v-col>
                  </v-row>
                </v-subheader>
                <v-divider v-if="designFiles.length > 0" />
                <v-list-item-group>
                  <v-list-item
                    v-for="(designFile, index) in designFiles"
                    :key="index"
                    :style="{ backgroundColor: rowColor(index) }"
                    dense
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row dense align="center">
                          <v-col
                            cols="12"
                            md="3"
                            class="body-1 text-capitalize"
                          >
                            {{ designFile.shortName.split("_").join(" ") }}
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-row>
                              <v-col
                                cols="12"
                                md="11"
                                class="d-flex justify-center"
                              >
                                <v-tooltip
                                  top
                                  v-if="designFilePackage[designFile.name]"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      block
                                      color="secondary"
                                      @click="
                                        onDownloadFilePackage(
                                          designFilePackage[
                                            `${designFile.shortName}_revision`
                                          ] && !isAdmin
                                            ? designFilePackage[
                                                `${designFile.shortName}_revision`
                                              ]
                                            : designFilePackage[designFile.name]
                                        )
                                      "
                                      v-on="on"
                                      >Download<v-icon right>
                                        mdi-cloud-download
                                      </v-icon></v-btn
                                    >
                                  </template>
                                  <span>{{
                                    designFilePackage[
                                      `${designFile.shortName}_revision`
                                    ] && !isAdmin
                                      ? designFilePackage[
                                          `${designFile.shortName}_revision`
                                        ]
                                          .split("?")[0]
                                          .split("/")
                                          .pop()
                                      : designFilePackage[designFile.name]
                                          .split("?")[0]
                                          .split("/")
                                          .pop()
                                  }}</span>
                                </v-tooltip>
                                <span v-else class="body-1">Not Generated</span>
                              </v-col>
                              <v-col
                                cols="12"
                                md="1"
                                class="d-flex justify-center"
                              >
                                <v-tooltip
                                  top
                                  v-if="
                                    (designFilePackage[designFile.shortName] ||
                                      designFilePackage[
                                        `${designFile.shortName}_revision`
                                      ]) &&
                                      !isAdmin
                                  "
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-icon small v-on="on" color="primary"
                                      >mdi-asterisk</v-icon
                                    >
                                  </template>
                                  <span
                                    >An admin has manually updated this
                                    file</span
                                  >
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="2" v-if="!isModalRendered">
                            <v-tooltip
                              top
                              v-if="
                                (designFilePackage[designFile.shortName] ||
                                  designFilePackage[
                                    `${designFile.shortName}_revision`
                                  ]) &&
                                  isAdmin
                              "
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  block
                                  color="secondary"
                                  v-on="on"
                                  @click="
                                    onDownloadFilePackage(
                                      designFilePackage[
                                        `${designFile.shortName}_revision`
                                      ]
                                    )
                                  "
                                  >Download<v-icon right>
                                    mdi-cloud-download
                                  </v-icon></v-btn
                                >
                              </template>
                              <span>{{
                                designFile.name != designFile.shortName
                                  ? designFilePackage[
                                      `${designFile.shortName}_revision`
                                    ]
                                      .split("?")[0]
                                      .split("/")
                                      .pop()
                                  : designFilePackage[designFile.shortName]
                                      .split("?")[0]
                                      .split("/")
                                      .pop()
                              }}</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="12" md="2" v-if="!isModalRendered">
                            <DesignFileUploadModal
                              v-if="isAdmin"
                              :designFilename="designFile.shortName"
                              @saveDesignFile="
                                onSaveDesignFile({
                                  id: designFilePackage.id,
                                  fieldName:
                                    designFile.name != designFile.shortName
                                      ? `${designFile.shortName}_revision`
                                      : designFile.shortName,
                                  data: $event,
                                })
                              "
                            />
                          </v-col>
                          <v-col cols="12" md="2" v-if="!isModalRendered">
                            <v-btn
                              block
                              color="warning"
                              v-if="
                                (designFilePackage[designFile.shortName] ||
                                  designFilePackage[
                                    `${designFile.shortName}_revision`
                                  ]) &&
                                  isAdmin
                              "
                              @click="
                                onDeleteDesignFile({
                                  id: designFilePackage.id,
                                  shortName: designFile.shortName,
                                  fieldName: `${designFile.shortName}_revision`,
                                })
                              "
                              >Delete</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </v-card>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @delete="deleteHandler"
      @deleteDFP="deleteDFPHandler"
      @donothing="stopDialog"
    />
  </v-container>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import { fileDownloader } from "@/helpers/basehelper.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FixtureDesignBlock",
  mixins: [BottomDialog],
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    designFilePackage: {
      type: Object,
      default: null,
    },
    selectedProject: {
      type: Object,
      default: null,
    },
    activeId: {
      type: Number,
      default: null,
    },
    isDFPView: {
      type: Boolean,
      default: false,
    },
    isModalRendered: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DutConfigBlock: () => import("./DutConfigBlock.vue"),
    DesignFileUploadModal: () => import("./DesignFileUploadModal.vue"),
    AddNotesModal: () => import("./AddNotesModal.vue"),
  },
  data() {
    return {
      showAllFiles: this.isModalRendered ? true : false,
      showDesignFileTable: this.isAdmin || this.isModalRendered ? true : false,
      receptacle50: 1.27,
      receptacle75: 1.91,
      receptacle100: 2.54,
      deletePayload: null,
    };
  },
  computed: {
    ...mapGetters({
      fixtureDesign: "fixturedesign/fixtureDesign",
      designFileOptions: "fixturedesign/designFileOptions",
    }),
    thumbnail() {
      return this.designFilePackage.thumbnail
        ? this.designFilePackage.thumbnail
        : null;
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    fixtureDescription() {
      return this.fixtureDesign.fixture_config.description;
    },
    dutConfigs() {
      return this.fixtureDesign.dut_configs;
    },
    testPoints() {
      return this.fixtureDesign.dut_configs.reduce(
        (totalQty, item) => totalQty + item.testpoints.length,
        0
      );
    },
    guidePins() {
      return this.fixtureDesign.dut_configs.reduce(
        (totalQty, item) => totalQty + item.guide_pins.length,
        0
      );
    },
    pressurePins() {
      return this.fixtureDesign.dut_configs.reduce(
        (totalQty, item) => totalQty + item.pressure_pins.length,
        0
      );
    },
    fixtureDesignPackageUrl() {
      return this.designFilePackage.design_package_zip;
    },
    fixtureDev() {
      return this.fixtureDesign.fixture_config.development_fixture;
    },
    p100Count() {
      let p100 = 0;
      for (let i = 0; i < this.dutConfigs.length; i++) {
        p100 += this.dutConfigs[i].testpoints.filter(
          (element) =>
            element.receptacle?.pitch &&
            element.receptacle?.pitch == this.receptacle100
        ).length;
      }
      return p100;
    },
    p75Count() {
      let p75 = 0;
      for (let i = 0; i < this.dutConfigs.length; i++) {
        p75 += this.dutConfigs[i].testpoints.filter(
          (element) =>
            element.receptacle?.pitch &&
            element.receptacle?.pitch == this.receptacle75
        ).length;
      }
      return p75;
    },
    p50Count() {
      let p50 = 0;
      for (let i = 0; i < this.dutConfigs.length; i++) {
        p50 += this.dutConfigs[i].testpoints.filter(
          (element) =>
            element.receptacle?.pitch &&
            element.receptacle?.pitch == this.receptacle50
        ).length;
      }
      return p50;
    },
    fixtureState() {
      return this.fixtureDesign.state;
    },
    designFiles() {
      return this.showAllFiles
        ? this.mappedFileOptions
        : this.mappedFileOptions.filter((element) => {
            return (
              this.designFilePackage[element.name] ||
              this.designFilePackage[`${element.shortName}_revision`]
            );
          });
    },
    mappedFileOptions() {
      return this.designFileOptions.reduce((mapped, element) => {
        if (
          element.name.split("_").pop() === "zip" ||
          element.name === "thumbnail"
        ) {
          //nothing here...
        } else if (
          element.name.split("_").pop() === "generation" ||
          element.name.split("_").pop() === "revision"
        ) {
          const shortName = element.name.substring(
            0,
            element.name.lastIndexOf("_")
          );
          const record = mapped.find((o) => o.shortName === shortName);
          if (!record) {
            mapped.push({
              name: element.name,
              shortName: shortName,
              data: element.data,
            });
          }
        } else {
          mapped.push({
            name: element.name,
            shortName: element.name,
            data: element.data,
          });
        }
        return mapped;
      }, []);
    },
    disableActiveButton() {
      return this.activeId
        ? this.activeId !== this.designFilePackage.id
        : false;
    },
    bomUrl() {
      return Object.keys(this.selectedProject.fixture_design_details).length > 0
        ? Object.values(this.selectedProject.fixture_design_details)[0]?.bom_url
        : null;
    },
  },
  methods: {
    fileDownloader,
    ...mapActions({
      fetchFixtureDesign: "fixturedesign/getFixtureDesign",
      updateDesignFilePackage: "fixturedesign/patchDesignFilePackage",
      removeDesignFilePackage: "fixturedesign/deleteDesignFilePackage",
      toggleLoading: "ui/loading",
    }),
    async onDownloadFilePackage(payload) {
      const actionPayload = {
        url: payload,
        file: `${this.partNumber} - ${payload.split("/").pop()}`,
      };
      this.toggleLoading("Downloading, one moment please");
      await this.fileDownloader(actionPayload);
      this.toggleLoading();
    },
    async onSaveDesignFile({ id, fieldName, data }) {
      console.log(data);
      let inputData = new FormData();
      inputData.append(fieldName, data);
      try {
        await this.updateDesignFilePackage({ id, data: inputData });
        this.toggleLoading("Refreshing data");
        await this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        });
        this.toggleLoading();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onDeleteFilePackage(id) {
      this.deletePayload = id;
      this.confirmAction = "deleteDFP";
      this.confirmMessage = `Are you sure you want to delete Design File Package version ${this.designFilePackage.version}?`;
      this.bottomDialog = true;
    },
    async deleteDFPHandler() {
      this.stopDialog();
      try {
        await this.removeDesignFilePackage(this.deletePayload);
        this.toggleLoading("Refreshing data");
        await this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        });
        this.toggleLoading();
        this.deletePayload = null;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    onDeleteDesignFile({ id, shortName, fieldName }) {
      this.deletePayload = { id, fieldName };
      this.confirmAction = "delete";
      this.confirmMessage = `Are you sure you want to delete the revised ${shortName
        .split("_")
        .join(" ")}?`;
      this.bottomDialog = true;
    },
    async deleteHandler() {
      this.stopDialog();
      let inputData = new FormData();
      inputData.append(this.deletePayload.fieldName, new File([], ""));
      try {
        await this.updateDesignFilePackage({
          id: this.deletePayload.id,
          data: inputData,
        });
        this.toggleLoading("Refreshing data");
        await this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        });
        this.toggleLoading();
        this.deletePayload = null;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onPatchDFP({ id, fieldName, data }) {
      let inputData = new FormData();
      inputData.append(fieldName, data);
      try {
        await this.updateDesignFilePackage({ id, data: inputData });
        this.toggleLoading("Refreshing data");
        await this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        });
        this.toggleLoading();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    rowColor(index) {
      return index % 2 !== 0 ? "#F5F5F5" : "white";
    },
    showTable(value) {
      this.showDesignFileTable = value;
    },
  },
};
</script>
